<template>
  <v-tree-catalog
      v-bind="{
        title,
        tableCellsConfig: filteredTableCellsConfig || tableCellsConfig,
        metricFilters,
        page: pageName
      }"
  />
</template>

<script>
import filterWSByProject from '@/mixins/filterWSByProject.js'
import treeCatalog from '@/services/components/treeCatalog/'

export default {
  components: {
    'v-tree-catalog': treeCatalog
  },

  mixins: [filterWSByProject],

  data () {
    return {
      pageName: 'catalog',

      title: {
        primary: 'Data analytics',
        secondary: 'Catalog'
      }
    }
  },

  computed: {
    tableCellsConfig() {
      return this.$store.getters['tableSettings/getTableCellsConfigCatalog']
    },

    metricFilters () {
      let items = [
        'ws2',
        'top3',
        'top5',
        'top10',
        'p_traf',
        'sessions',
        'users',
        'new_users',
        'transactions',
        'conversions',
        'transaction_revenue',
      ]
      if (!this.shouldFilterWSMetrics)  {
        items.push('ws1')
        items.push('ws3')
      }
      return items
    }
  }
}
</script>
